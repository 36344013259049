<template>
  <div class="container-fluid area p-0 Overflow-hidden">
    <div>
      <img
        :src="require(`@/assets/images/background.png`)"
        alt=""
        class="height-100 w-100"
      />
    </div>
    <div class="container form-area position-relative">
      <form v-on:submit.prevent="onSubmit">
        <div class="dreamp_Program_content pt-2 pb-3">
          <h2 class="heading mb-3 wrap-text text-primary">Add List</h2>
          <div class="form-group w-100">
            <label for="title"
              ><span class="textstyle font-weight-bold"
                >Title<span class="text-danger font-weight-bold mt-1"
                  >*</span
                ></span
              ></label
            >
            <span class="textstyle ml-2"
              >{{ RemainLength }}/{{ title_limit }}</span
            >
            <input
              type="text"
              class="form-control"
              placeholder=""
              v-model="title"
              v-on:input="ch()"
            />
          </div>
          <div class="mb-2 mt-0">
            <label for="industry" class="w-100"
              ><span class="textstyle font-weight-bold">Industry</span
              ><span class="text-danger font-weight-bold">*</span>
            </label>
            <multiselect
              v-model="IndustryValue"
              :options="industries"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some"
            >
            </multiselect>
          </div>

          <div id="editor" class="mt-4">
            <label for="short_description"
              ><span class="textstyle font-weight-bold mb-5"
                >Short Description</span
              ><span class="text-danger font-weight-bold">*</span></label
            >
            <textarea
              class="mt-3 w-100"
              v-model="short_description"
              rows="5"
            ></textarea>
          </div>

          <div id="editor2" class="mt-4">
            <label for="long_description"
              ><span class="textstyle font-weight-bold">Long Description</span
              ><span class="text-danger font-weight-bold">*</span></label
            >
            <ckeditor
              class="bg-danger"
              :editor="editor"
              v-model="long_description"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="pt-3">
            <label for="price"
              ><span class="textstyle font-weight-bold"
                >Price<span class="text-danger font-weight-bold">*</span></span
              ></label
            >
            <input
              type="number"
              min="0"
              class="form-control bg-white"
              style="outline: none; width: 200px"
              placeholder=""
              v-model="price"
            />
          </div>

          <div class="pt-3 pb-3">
            <label for="tags" class="w-100"
              ><span class="textstyle font-weight-bold">Tags</span
              ><span class="text-danger font-weight-bold">*</span>
            </label>
            <multiselect
              v-model="tagValue"
              tag-placeholder="Add this as new tag"
              placeholder="Add a tag"
              label="name"
              track-by="name"
              :options="tempTags"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
            >
            </multiselect>
          </div>

          <div class="d-flex justify-content-between flex-sm-column">
            <div id="app">
              <div class="input-file-wrap mt-3 d-flex justify-content-start">
                <label class="fileUploadBtn__label">
                  <input
                    class="input-file__input"
                    id="file_upload"
                    name="file"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    @change="onFilePicked($event)"
                  />upload file</label
                >
                <span class="txt__light ml-2 mt-2"
                  >(Upload CSV and XLSX file only. File size should be below
                  15MB.)</span
                >
              </div>
              <span class="ml-2">{{ file_name }}</span>
            </div>
            <!-- <div
              class="
                image-download
                mt-3
                d-flex
                justify-content-end justify-content-sm-start
              "
            >
              <a href="" @click="download" download
                ><p class="para text-dark">
                  Download the sample file
                  <span class="ml-2">
                    <svg
                      id="Group_6"
                      data-name="Group 6"
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="10"
                      viewBox="0 0 9 10"
                    >
                      <path
                        id="Shape"
                        d="M6.3-4.5V-8.1H2.7v3.6H0L4.5,0,9-4.5H6.3Z"
                        transform="translate(0 8.098)"
                        fill="#777c82"
                      />
                      <path
                        id="Line_5"
                        data-name="Line 5"
                        d="M.5.5h8"
                        transform="translate(0 9)"
                        fill="none"
                        stroke="#777c82"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="1"
                      />
                    </svg>
                  </span>
                </p>
              </a>
            </div> -->
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button v-if="!loading" class="btnwrap" @click="submit()">
              Submit
            </button>
            <!-- <input type="submit" class="btnwrap" v-if="!loading" /> -->
            <div  v-else class="spin-wrap btnwrap">
              <i class="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      editor: ClassicEditor,
      short_description: "",
      long_description: "<p></p>",
      editorConfig: {
        // The configuration of the editor.
         toolbar: ['heading', '|', 'fontfamily', 'fontsize','bold', 'italic', '|', 'link' , 'undo', 'redo', '|', 'numberedList', 'bulletedList',]
      },
      title: "",
      IndustryValue: [],
      tagValue: [],
      price: null,
      list_file: null,
      file_name: "",
      industries: [],
      tags: [],
      tempTags: [],
      title_limit: 100,
      loading: false,

      // sampleCSV: "../assets/SampleCSV/SampleCSV.csv",
    };
  },
  created() {
    this.getIndustries();
  },
  computed: {
    RemainLength() {
      var l = this.title.length;
      //  var remain = this.title_limit-l
      return l;
    },
  },
  methods: {
    ch() {
      if (this.RemainLength >= this.title_limit) {
        this.title = this.title.substring(0, this.title_limit);
        return;
      }
    },
    
    download() {
      const url = "src/assets/SampleCSV/SampleCSV.csv";
      window.location.href = url;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.tagValue.push(tag);
      this.tags.push(tag.name);
    },

    previewFiles(event) {
      this.file = event.target.files[0];
    },
    onFilePicked(event) {
      this.list_file = event.target.files;
      if (!this.list_file.length) {
        this.list_file = null;
        this.file_name = "";
        return;
      }
      let extensionArr = [
        "csv",
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      if (
        this.list_file[0].type != "" &&
        extensionArr.indexOf(this.list_file[0].type) == -1
      ) {
        this.$toasted.show(
          "Please upload a valid CSV or Excel file. Please upload an Excel file if your CSV file is not uploading!",
          {
            icon: "times-circle",
            className: "error-class",
          }
        );
        this.list_file = null;
        this.file_name = "";
        return;
      }
      let max_file_size = 15 * 1024 * 1024;
      if (this.list_file[0].size < max_file_size) {
        this.file_name = this.list_file[0].name;
      } else {
        this.$toasted.show("Please upload a CSV or XLSX file below 15MB!", {
          icon: "times-circle",
          className: "error-class",
        });
        this.list_file = null;
        this.file_name = "";
      }
    },
    getIndustries() {
      this.formData = new FormData();
      window.axiosP
        .post("all-industry-list", this.formData)
        .then((res) => {
          this.industries = res.data.industries;
          // industries = [...new Set(industries)];
          // industries.map((val) => {
          //   this.industry.push(val);
          // });
          // this.industry_page++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validCKeditor(text) {
      var tl = text.length;

      if (tl == 7 || tl == 0) {
        return 0;
      } else {
        return 1;
      }
    },
    onSubmit() {  
      if (!this.title.replace(/\s/g, "").length) {
        this.$toasted.show("Please type a valid title!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      if (!this.IndustryValue.length) {
        this.$toasted.show("Please select an industry!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      if (!this.short_description) {
        this.$toasted.show("Short description is required !", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      if (!this.short_description.replace(/\s/g, "").length) {
        this.$toasted.show("Please input a valid short description", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      if (!this.validCKeditor(this.long_description)) {
        this.$toasted.show("Please input long description!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      if (isNaN(this.price)) {
        this.$toasted.show("Price should be an integer!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      } else {
        if (this.price < 1) {
          this.$toasted.show("Please input a valid price amount!", {
            icon: "times-circle",
            className: "error-class",
          });
          return;
        }else if(this.price.length >= 11){
          this.$toasted.show("This is too much!", {
            icon: "times-circle",
            className: "error-class",
          });
          return;
        }
      }

      if (!this.tagValue.length) {
        this.$toasted.show("Please type valid tags!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }

      if (this.list_file == null) {
        this.$toasted.show("Please select a file!", {
          icon: "times-circle",
          className: "error-class",
        });
        return;
      }
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("title", this.title);
      this.formData.append("short_description", this.short_description);
      this.formData.append("long_description", this.long_description);
      this.formData.append("Industries", this.IndustryValue);
      this.formData.append("tags", this.tags);
      this.formData.append("price", this.price);
      this.formData.append("page", this.industry_page);
      this.formData.append("file", this.list_file[0]);

      window.axiosHeaderP
        .post("upload-csv", this.formData)
        .then((res) => {
          this.$toasted.show(res.data.message, {
            icon: "check-circle",
            className: "success-class",
          });
        this.loading = false;

          this.$router.push("/");
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.message, {
            className: "success-class",

          });
          this.loading = false;

          // console.log("catch");
          // console.log(err);
          // alert(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 20px;
  font-weight: 900;
}
.textstyle {
  font-size: 13px;
  line-height: 27px;
}
.btnwrap[data-v-0b6c7bbd] {
  background: #0091ff;
  color: #fff;
  border: none;
  padding: 9px 20px;
  border-radius: 3px;
  font-weight: 700;
}
.form-area {
  background-color: #ffff;
  box-shadow: 0px 0px 25px #c6c6c6a3;
  border-radius: 5px;
  padding: 50px;
  margin-top: -295px;
}
.fileUploadBtn__label {
  display: inline-block;
  font-size: 14px;
  color: var(--txtColor, #001e4c);
  line-height: var(--NavInputHeight, 40px);
  padding: 0 25px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  cursor: pointer;
}
.fileUploadBtn__label > input[type="file"] {
  display: none;
}
.spin-wrap {
  outline: none;
  border: none;
  padding: 9px 20px;
  border-radius: 3px;
}

.fa-spin {
  color: #ffff;
  font-size: 30px;
}
@media (max-width: 1600px) {
  .form-area {
    margin-top: -182px;
  }
}
@media (max-width: 992px) {
  .form-area {
    margin-top: -137px;
  }
}
@media (max-width: 768px) {
  .form-area {
    margin-top: -97px;
  }
}
@media (max-width: 576px) {
  .form-area {
    margin-top: -47px;
  }
}
</style>
<style>
.ck-editor__editable_inline {
  min-height: 300px !important;
}
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
</style>
